import React from 'react';
import PropTypes from 'prop-types';

import ContentBackground from 'core/components/ContentBackground';
import Shapka, { StickyContainer } from 'core/components/Shapka';
import Layout, { Header, Content, Footer } from 'core/components/Layout';
import { Mobile, Desktop } from 'core/components/breakpoint';
import StoryTopics from 'core/components/stories/StoryTopics';
import { SideIndent } from 'core/components/Wrappers';
import CookiesBanner from 'core/components/CookiesBanner';
import withBreakpoint from 'core/components/breakpoint/withBreakpoint';

import { BLOCK_VERTICAL_MOBILE, BLOCK_VERTICAL } from 'site/components/Indents';

import Scooter from '../Scooter';
import { VerticalIndent } from '../Indents';

import { Billboard, Context } from '../Ads/desktop';

import { TopBanner, Footer as FooterAd } from '../Ads/mobile';

function SiteLayout({ children, isMobile }) {
  return (
    <StickyContainer>
      <ContentBackground>
        <Layout>
          <Header>
            <Shapka />
          </Header>
          <Content>
            <Desktop>
              <Billboard />
            </Desktop>
            <Mobile>
              <TopBanner />
            </Mobile>
            <SideIndent>
              <StoryTopics
                top={isMobile ? BLOCK_VERTICAL_MOBILE : BLOCK_VERTICAL}
                bottom={isMobile ? BLOCK_VERTICAL_MOBILE : BLOCK_VERTICAL}
              />
            </SideIndent>
            {children}
          </Content>
          <Footer>
            <Desktop>
              <Context count={2} />
            </Desktop>
            <Mobile>
              <FooterAd />
            </Mobile>
            <VerticalIndent />
            <Scooter />
          </Footer>
          <CookiesBanner />
        </Layout>
      </ContentBackground>
    </StickyContainer>
  );
}

SiteLayout.propTypes = {
  isMobile: PropTypes.bool,
};

export default withBreakpoint(SiteLayout);
