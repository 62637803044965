import { Fragment } from 'react';
import PropTypes from 'prop-types';

import Bouesque from 'core/components/Bouesque';
import StickyPortal from 'core/components/StickyPortal';

import { VerticalIndent, BLOCK_VERTICAL } from 'site/components/Indents';
import { PartnersVertical } from 'site/components/Partners';
import AdWrapper from 'core/components/Ad/AdWrapper';
import SelfPromo from 'core/components/SelfPromo';

import StarsBirthday from 'site/components/StarsBirthday';
import {
  AdPlace2,
  AdPlace3,
} from 'site/components/Ads/desktop';

export default function InnerSideColumn() {
  return (
    <Fragment>
      <Bouesque />
      <VerticalIndent />
      <StickyPortal listenStickyRemoveEvent>
        <AdWrapper bottom={BLOCK_VERTICAL}>
          <AdPlace2 />
        </AdWrapper>
        <SelfPromo />
        <VerticalIndent />
        <StarsBirthday />
        <VerticalIndent />
        <PartnersVertical />
        <VerticalIndent />
      </StickyPortal>
      <StickyPortal>
        <AdPlace3 />
      </StickyPortal>
    </Fragment>
  );
}

export function InnerSideColumnShort({ hideBouesque }) {
  return (
    <AdWrapper bottom={BLOCK_VERTICAL}>
      {!hideBouesque && (
        <>
          <Bouesque />
          <VerticalIndent />
        </>
      )}
      <AdPlace2 />
      <StarsBirthday />
      <VerticalIndent />
      <AdPlace3 />
    </AdWrapper>
  );
}

InnerSideColumnShort.propTypes = {
  hideBouesque: PropTypes.bool,
};
