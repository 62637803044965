import color from 'core/libs/color';
import deepmerge from 'core/libs/deepmerge';

import { MOBILE, DESKTOP } from 'core/components/breakpoint/values';
import bindProps from 'core/components/bindProps';

import {
  BLOCK_HORIZONTAL,
  BLOCK_VERTICAL,
} from 'site/components/Indents';

import Logo from '../components/Logo';
import Comments from '../components/Icon/Comments';
import DefaultAvatar from '../components/Icon/DefaultAvatar';
import ArrowLeft from 'site/icons/ArrowLeftRound';
import ArrowRight from 'site/icons/ArrowRightRound';
import Video from 'site/icons/Video';
import Photo from 'site/icons/Photo';

import Card4 from 'site/cards/Card4';
import StoryStatusLine from 'site/components/StoryStatusLine';

import notFoundImg from 'site/pages/images/404.png';
import jsError from 'site/pages/images/jsError.png';

import fonts from './fonts';

const icons = {
  logo: Logo,
  comments: Comments,
  DefaultAvatar,
  arrowLeft: ArrowLeft,
  arrowRight: ArrowRight,
  Video,
  Photo,
};

const layout = {
  sideColumnIndent: '54px',
  contentColumnMaxWidth: '816px',
  indents: {
    top: BLOCK_VERTICAL,
    bottom: BLOCK_VERTICAL,
    left: BLOCK_HORIZONTAL,
    right: BLOCK_HORIZONTAL,
  },
};

const colors = {
  adsColor: '#fff',
};

const subtitleError = (
  <div>Уууупс! <br /> Такой страницы нет </div>
);

export const scooterMenuLink = {
  font: `14px/1 ${fonts.nuance}`,
  letterSpacing: '1px',
  textTransform: 'uppercase',
};


export default function baseTheme(atoms) {
  const animations = {
    hover: '150ms cubic-bezier(0.23, 1, 0.32, 1)',
  };

  const texts = {
    headline: {
      font: `48px/1.1 ${fonts.display}`,
    },
    title1: {
      font: `500 56px/1.2 ${fonts.display}`,
    },

    lead: {
      font: `300 24px/34px ${fonts.display}`,
      color: atoms.colors.primary,
      marginTop: '27px',
      letterSpacing: 'normal',
    },

    caption1: {
      font: `14px/18px ${fonts.nuance}`,
    },

    caption2: {
      font: `14px/18px ${fonts.nuance}`,
    },

    caption4: {
      font: `500 14px/18px ${fonts.nuance}`,
    },
  };

  const drum = {
    arrow: {
      fade: {
        outer: 'transparent',
        inner: 'transparent',
      },
      width: 60,
      height: 60,
      transform: 'translate("50%", 0)',
    },
  };

  const mark = {
    padding: '0 0.1em',
    background: '#ECE5E0',
  };

  const topicFooter = {
    commentButton: {
      type: 'ghost',
      text: 'Обсудить',
      textTransform: 'uppercase',
      font: `400 18px/20px ${fonts.nuance}`,
      size: 'large',
      topSpacing: 25,
      icon: {
        width: '42px',
        height: '39px',
        Icon: Comments,
        showValueInIcon: true,
      },
    },
    socialMedia: {
      type: 'flat',
    },
  };

  const bullet = {
    basic: {
      active: {
        background: atoms.colors.primary,
      },
    },
  };

  const topicHeaderTexts = {
    title: {
      font: `500 40px/50px ${fonts.display}`,
      letterSpacing: 'normal',
      color: atoms.colors.primary,
    },
    subtitle: {
      font: `200 italic 40px/50px ${fonts.display}`,
      letterSpacing: 'normal',
      color: atoms.colors.primary,
    },
    lead: { ...texts.lead },
    divider: {
      background: color(atoms.colors.primary).alpha(0.15).string(),
      margin: '23px calc(50% - 125px)',
    },
    expert: {
      margin: '25px auto 0 auto',
    },
  };

  const authorPage = {
    name: {
      font: `28px/1.3 ${fonts.display}`,
    },
  };

  const authorsPage = {
    name: {
      font: `500 16px/1.2 ${fonts.display}`,
    },
  };

  const avatar = {
    border: '3px solid #fff',
    background: 'linear-gradient(0deg, rgba(35, 46, 58, 0.15), rgba(35, 46, 58, 0.15)), #FFFFFF',
    boxShadow: '0px 4px 14px rgba(0, 0, 0, 0.15)',
  };

  const errorPage = {
    imgUrl: notFoundImg,
    background: atoms.colors.content,
    imgWidth: 255,
    title: {
      font: `250 160px/184px ${fonts.nuance}`,
    },
    subtitle: {
      font: `28px/36px ${fonts.text}`,
    },
    card: Card4,
  };

  const shapkaLink = {
    font: `500 11px/1 ${fonts.nuance}`,
    textTransform: 'uppercase',
  };

  const smartTeaser = {
    carRelease: {
      title: {
        font: `500 20px/25px ${fonts.display}`,
      },
      info: {
        font: `400 18px/26px ${fonts.display}`,
      },
      link: {
        hover: {
          color: atoms.colors.active1,
        },
      },
    },
  };

  const link = {
    secondary: {
      idle: {
        color: atoms.colors.primary,
      },

      hover: {
        color: color(atoms.colors.active2).mix(color(atoms.colors.primary), 0.2).string(),
      },

      active: {
        color: color(atoms.colors.active2).mix(color(atoms.colors.primary), 0.2).string(),
      },

      visited: {
        color: atoms.colors.primary,
      },
    },
  };

  const button = {
    const: {
      radius: 0,
      textTransform: 'uppercase',
      sizes: {
        extraSmall: {
          font: `10px/1 ${fonts.nuance}`,
        },
        small: {
          font: `12px/1.2px ${fonts.nuance}`,
        },
        medium: {
          font: `14px/1.2 ${fonts.nuance}`,
          height: '50px',
        },
        large: {
          font: `20px/1.2 ${fonts.nuance}`,
        },
        extraLarge: {
          font: `16px/18px ${fonts.nuance}`,
          height: '40px',
          padding: '10px 40px',
        },
      },
    },

    primary: {
      idle: {
        background: atoms.colors.active2,
      },

      hover: {
        background: (
          color(atoms.colors.active2)
            .mix(color(atoms.colors.primary), 0.1)
            .string()
        ),
      },

      active: {
        background: (
          color(atoms.colors.active2)
            .mix(color(atoms.colors.primary), 0.1)
            .string()
        ),
      },

      disabled: {
        background: color(atoms.colors.active2).lighten(0.15).string(),
      },
    },
    secondary: {
      idle: {
        color: 'rgba(0,0,0,.6)',
        background: 'none',
        border: '1px solid rgba(0,0,0,.2)',
      },
      hover: {
        color: atoms.colors.primary,
        background: color(atoms.colors.active2).alpha(0.2).string(),
        border: `1px solid ${atoms.colors.active2}`,
      },
      active: {
        color: atoms.colors.primary,
        background: color(atoms.colors.active2).alpha(0.2).string(),
        border: `1px solid ${atoms.colors.active2}`,
      },
      disabled: {
        color: color(atoms.colors.primary).alpha(0.5).string(),
        background: 'none',
        border: `1px solid ${color(atoms.colors.active2).alpha(0.7).string()}`,
      },
    },
    tertiary: {
      idle: {
        color: atoms.colors.layout,
        background: atoms.colors.darkBlue,
        border: `1px solid ${atoms.colors.darkBlue}`,
      },

      hover: {
        color: atoms.colors.layout,
        background: color(atoms.colors.darkBlue).alpha(0.9).string(),
        border: `1px solid ${color(atoms.colors.darkBlue).darken(0.15).string()}`,
      },

      active: {
        color: atoms.colors.layout,
        background: atoms.colors.darkBlue,
        border: `1px solid ${atoms.colors.darkBlue}`,
      },

      disabled: {
        color: atoms.colors.layout,
        background: atoms.colors.darkBlue,
        border: `1px solid ${color(atoms.colors.darkBlue).alpha(0.9).string()}`,
      },
    },
    ghost: {
      idle: {
        fill: atoms.colors.footer,
      },
      hover: {
        fill: atoms.colors.primary,
      },
      active: {
        fill: atoms.colors.footer,
      },
      disabled: {
        color: color(atoms.colors.primary).alpha(0.4).string(),
      },
    },
  };

  const shapka = {
    link: {
      ...shapkaLink,
      padding: '10px 15px',
    },
    logo: {
      Icon: bindProps({ isHeader: true })(Logo),
      width: 154,
      height: 30,
    },
    height: 110,
    padding: `0 ${BLOCK_HORIZONTAL}px`,
    dropdown: {
      link: {
        ...shapkaLink,
      },
    },

    labelNoAds: {
      fontWeight: 500,
      color: '#000',
      textTransform: 'uppercase',
    },
  };

  const scooter = {
    border: `1px solid ${atoms.colors.divider}`,
    background: '#000',
    padding: `0 ${BLOCK_HORIZONTAL}px`,
    menuLink: scooterMenuLink,
    social: {
      size: 40,
      iconSize: 20,
      margin: '0 0 0 14px',
      idle: {
        color: atoms.colors.primary,
        background: 'transparent',
        border: 'none',
      },
      hover: {
        color: atoms.colors.active1,
        background: 'transparent',
        border: 'none',
      },
    },
    logo: {
      width: 154,
      height: 30,
    },
  };

  const topicPages = {
    arrow: {
      idle: {
        color: atoms.colors.active1,
      },

      hover: {
        fill: atoms.colors.active1,
      },
    },
  };

  const input = {
    const: {
      radius: 0,
      sizes: {
        extraSmall: {
          font: `12px/1 ${fonts.nuance} !important`,
        },
        small: {
          font: `16px/18px ${fonts.nuance}`,
        },
        medium: {
          font: `20px/24px ${fonts.nuance}`,
        },
      },
    },
    basic: {
      idle: {
        background: 'transparent',
        border: `1px solid ${atoms.colors.placeholder}`,
      },

      hover: {
        background: 'transparent',
        border: `1px solid ${atoms.colors.placeholder}`,
      },

      focused: {
        background: 'transparent',
        border: `1px solid ${atoms.colors.hint}`,
      },

      disabled: {
        background: atoms.colors.hint,
        border: `1px solid ${atoms.colors.placeholder}`,
      },

      error: {
        background: 'transparent',
        border: `1px solid ${atoms.colors.error}`,
      },
    },
  };

  const bouesque = {
    const: {
      size: 'medium',
    },
  };

  const comments = {
    const: {
      CountIcon: icons.comments,
    },
  };

  const vikontImage = {
    maxWidth: 795,
  };

  const vikontReadMore = {
    block: {
      background: atoms.colors.layout,
      padding: '20px 24px',
    },
    title: {
      color: color(atoms.colors.primary).alpha(0.5).string(),
      font: `400 14px/1.2 ${fonts.nuance}`,
      textTransform: 'uppercase',
      letterSpacing: '0.08em',
      marginBottom: '20px',
    },
    drum: {
      arrowsShiftVertical: 100,
      spaceBetween: 20,
      sideIndent: 24,
    },
    singleCard: {
      alignItems: 'center',
    },
  };

  const spammer = {
    Icon: () => null,
    [DESKTOP]: {
      borderRadius: '0',
      formElements: {
        radius: '0',
      },
    },
    [MOBILE]: {
      borderRadius: '0',
      formElements: {
        radius: '0',
      },
    },
  };

  const commentsPreview = {
    headline: {
      font: `500 28px/1 ${fonts.text}`,
      color: atoms.colors.primary,
    },
    name: {
      font: `500 16px/20px ${fonts.text}`,
    },
    date: {
      font: `normal 12px/16px ${fonts.text}`,
    },
    comment: {
      font: `normal 16px/20px ${fonts.text}`,
    },
  };

  const author = {
    types: {
      expert: {
        background: atoms.colors.primary,
        color: atoms.colors.input,
        borderRadius: '0',
        textTransform: 'uppercase',
        border: 'none',
      },
    },
  };

  const personal = {
    circleCheckMark: {
      fill: atoms.colors.active1,
      stroke: '#000',
    },

    profileAvatar: {
      isCircular: true,
    },
  };

  const topicTopline = {
    font: `14px/1 ${fonts.nuance}`,
    color: color(atoms.colors.primary).alpha(0.3).string(),
    textTransform: 'uppercase',
    rubric: {
      color: atoms.colors.primary,
      padding: '0',
      border: 'none',
      borderRadius: '0',
      hover: {
        color: color(atoms.colors.active2).mix(color(atoms.colors.primary), 0.2).string(),
        background: 'transparent',
      },
    },
  };

  const expert = {
    name: {
      idle: {
        font: `600 14px/16px ${fonts.nuance}`,
        borderBottom: `1px solid ${atoms.colors.primary}`,
        textTransform: 'uppercase',
      },
      hover: {
        color: atoms.colors.primary,
        font: `600 14px/16px ${fonts.nuance}`,
        borderBottom: '1px solid transparent',
        textTransform: 'uppercase',
      },
    },
    jobTitle: {
      color: atoms.colors.hint,
      font: `400 14px/19px ${fonts.nuance}`,
    },
    label: {
      background: atoms.colors.primary,
      font: `400 10px/1 ${fonts.nuance}`,
      letterSpacing: '0.02em',
    },
  };

  const notes = {
    background: atoms.colors.layout,
  };

  const story = {
    placeholder: {
      backgroundColor: atoms.colors.placeholder2,
    },
  };

  const storyCard = {
    headline: {
      font: `600 11px/14px ${fonts.display}`,
      textTransform: 'uppercase',
    },
    card: {
      border: `4px solid ${atoms.colors.card}`,
    },
    statusLine: {
      border: `2px solid ${atoms.colors.active2}`,
      indent: '7px',
      Custom: StoryStatusLine,
    },
  };

  const storyWidgetsBaseCard = {
    headline: {
      font: `500 22px/28px ${fonts.display}`,
    },
  };

  const storyWidgetsVariants = {
    headline: {
      font: `500 22px/28px ${fonts.display}`,
      textTransform: 'uppercase',
    },

    states: {
      idle: {
        caption: {
          background: atoms.colors.active2,
        },
        image: {
          boxShadow: `-5px 5px 0 0 ${atoms.colors.active2}`,
        },
      },
      hover: {
        caption: {
          background: atoms.colors.active2,
        },
        image: {
          boxShadow: `-6px 6px 0 0 ${atoms.colors.active2}`,
        },
      },
      checked: {
        caption: {
          background: atoms.colors.active2,
        },
        image: {
          boxShadow: `-5px 5px 0 0 ${atoms.colors.active2}`,
        },
      },
      disabled: {
        caption: {
          background: atoms.colors.content,
        },
        image: {
          boxShadow: `-5px 5px 0 0 ${atoms.colors.content}`,
        },
      },
    },

    twoVariants: {
      caption: {
        font: `22px/1.2 ${fonts.nuance}`,
      },
    },
    threeVariants: {
      caption: {
        font: `16px/1.2 ${fonts.nuance}`,
      },
    },
  };

  const storyWidgetsRatingCard = {
    headline: {
      font: `500 22px/28px ${fonts.display}`,
      textTransform: 'uppercase',
    },
    count: {
      font: `110px/1 ${fonts.display}`,
    },
    percent: {
      font: `32px/40px ${fonts.display}`,
      bottomIndent: '14px',
    },
  };

  const storyWidgetsHiddenPhoto = {
    question: {
      color: atoms.colors.content,
      font: `500 22px/28px ${fonts.display}`,
      textTransform: 'uppercase',
    },
    variant: {
      borderRadius: 0,
      font: `14px/1.2 ${fonts.nuance}`,
      padding: '10px 20px',
      textTransform: 'uppercase',
      hover: {
        border: `1px solid ${atoms.colors.active2}`,
        background: atoms.colors.active2,
      },
      checkedCorrect: {
        border: `1px solid ${atoms.colors.active2}`,
        background: atoms.colors.active2,
      },
    },
    answerText: {
      font: `500 16px/18px ${fonts.display}`,
    },

    states: {
      correct: {
        answerText: {
          background: atoms.colors.active2,
        },
      },
    },
  };

  const topicContent = {
    widgetsMaxWidth: 720,
    widgetsWithNegativeMobileIndent: {
      'smartTeaser': true,
    },
  };

  const paginatron = {
    radius: button.const.radius,
    icon: {
      disabled: {
        fill: button.ghost.disabled.color,
      },
    },
    outerButtons: {
      innerBorder: button.secondary.idle.border,
      hover: {
        background: button.secondary.hover.background,
      },
    },
  };

  const skeleton = {
    background: atoms.colors.placeholder2,
  };

  const login = {
    popup: {
      button: {
        font: `400 16px/19px ${fonts.nuance}`,
      },
      content: {
        font: `400 12px/18px ${fonts.nuance}`,
      },
    },
    modal: {
      button: {
        font: `600 16px/21px ${fonts.nuance}`,
        color: button.primary.idle.color,
      },
      content: {
        font: `400 16px/23px ${fonts.nuance}`,
        color: atoms.colors.text,
      },
    },
  };

  const cookiesBanner = {
    background: atoms.colors.layout,
    text: {
      color: atoms.colors.primary,
    },
    button: {
      btnType: 'primary',
      btnRadius: '0px',
      btnText: `600 14px/16px ${fonts.text}`,
    },
  };

  return deepmerge({
    fonts,
    icons,
    texts,
    animations,
    controls: {
      avatar,
      authorPage,
      authorsPage,
      topicHeaderTexts,
      drum,
      topicFooter,
      bullet,
      link,
      button,
      shapka,
      scooter,
      smartTeaser,
      topicPages,
      input,
      bouesque,
      comments,
      vikontImage,
      vikontReadMore,
      spammer,
      commentsPreview,
      author,
      personal,
      topicTopline,
      expert,
      notes,
      mark,
      story,
      storyCard,
      storyWidgetsBaseCard,
      storyWidgetsVariants,
      storyWidgetsRatingCard,
      storyWidgetsHiddenPhoto,
      topicContent,
      paginatron,
      skeleton,
      login,
      cookiesBanner,
    },
    ads: {
      sponsored: {
        font: `16px/23px ${fonts.display}`,
        color: atoms.colors.active1,
      },
      urlPreviewImage: {
        retina: require('site/images/macbookPreview-2x.png'),
        common: require('site/images/macbookPreview-1x.png'),
      },
    },
    pages: {
      error: errorPage,
      notFound: {
        ...errorPage,
        subtitle: {
          ...errorPage.subtitle,
          name: subtitleError,
        },
      },
      sentryFallback: {
        ...errorPage,
        background: atoms.colors.content,
        imgUrl: jsError,
        card: null,
        subtitle: {
          ...errorPage.subtitle,
          name: 'Мы уже знаем об этом и скоро всё исправим',
        },
        title: {
          name: 'Что-то пошло не так',
          font: `275 80px/92px ${fonts.nuance}`,
        },
      },
    },
    layout,
    colors,
  }, atoms);
}
