import useRequest from 'core/hooks/useRequest';
import { rawContentQuery } from 'core/queries/searchPage';

import SearchPage from 'core/components/SearchPage';
import ColumnLayout from 'core/components/ColumnLayout';
import { PageIndent } from 'core/components/Wrappers';

import Boroda from 'site/components/Boroda';
import { InnerSideColumnShort } from 'site/components/InnerSideColumn';

import { VerticalIndent } from 'site/components/Indents';

import {
  Context,
  SuperFooter,
} from 'site/components/Ads/desktop';

import {
  Listing1,
  ListingSpecSearch,
} from 'site/components/Ads/mobile';

import Card7 from 'site/cards/Card7';

import filters from './filters';


function Search(props) {
  const { data: rawContent, isLoading } = useRequest(rawContentQuery(props));

  return (
    <PageIndent>
      <ColumnLayout rightColumn={<InnerSideColumnShort hideBouesque />}>
        <SearchPage
          rawContent={rawContent}
          isLoading={isLoading}
          filters={filters}
          ad1={SuperFooter}
          ad2={Context}
          ad1Mobile={Listing1}
          ad2Mobile={ListingSpecSearch}
          card={Card7}
        />
      </ColumnLayout>
      <VerticalIndent />
      <Boroda />
      <VerticalIndent />
    </PageIndent>
  );
}

export default Search;
