import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'core/libs/router';

import useRequest from 'core/hooks/useRequest';
import { topicsQuery } from 'core/queries/topics';

import { denormalizeData } from 'core/utils/api';

import { dateFormat } from 'core/utils/dates';

export const StarsBirthdayContext = React.createContext({
  starsBirthday: [],
});

function StarsBirthdayProvider({ children, history }) {
  const { data: starsBirthday, isLoading } = useRequest(topicsQuery({
    limit: 10,
    include: 'image,person_meta',
    person_birth_date: dateFormat(new Date(), 'yyyy-MM-dd'),
    history,
    renderError: () => [],
  }, { select: denormalizeData }));

  return (
    <StarsBirthdayContext.Provider value={{ starsBirthday, isLoading }}>
      {children}
    </StarsBirthdayContext.Provider>
  );
}

StarsBirthdayProvider.propTypes = {
  history: PropTypes.object,
};

export default withRouter(StarsBirthdayProvider);
